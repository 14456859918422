import React from "react";
import '../style/global.css';

const WhatsappThanks = ({setStep}) => {

    const openWhatsapp = () => {
        window.open('https://wa.me/+212777606249', '_blank');
    }

    return (
        <div className="wrapper">
            <h2 className="formTitle">تواصل معنا مباشرةً عبر واتساب لتجاوز الانتظار</h2>
            {/*whatsapp contact button*/}
            <div className="button whatsapp" onClick={openWhatsapp}>Whatsapp Contact</div>
                <p className="subtitle">
                    حاليًا، <span>123,332</span> شخصًا في قائمة الانتظار.
                </p>
        </div>
    );
};

export default WhatsappThanks;