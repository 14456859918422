import React from "react";
import '../style/global.css';
import Selector from "./Selector";
import api from "../services/api";
import { useLocation } from "react-router-dom";

const UserSignUp = ({ setStep }) => {

    const [formStep, setFormStep] = React.useState(1);
    const [interests, setInterests] = React.useState([]);
    const [city, setCity] = React.useState("");

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const googleId = queryParams.get('userId');
    const email = queryParams.get('email');


    const nextStep = () => {
        setFormStep(formStep + 1);
    }

    const submit = () => {
        api.post('/user', {interests, city, googleId, email}).then((res) => {
            window.location.href = "/share";
        }).catch((err) => {
            window.notifyRed('Error while submitting your information.');
        });
    }

    switch (formStep) {
        case 1:
            return <InterestSelect nextStep={nextStep} onSelect={ setInterests } />;
        case 2:
            return <CityInput submit={submit} onChange={(e) => setCity(e.target.value)} />;
        default:
            return "";
    }

};

const InterestSelect = ({ onSelect, nextStep }) => {
    return (
        <div className="wrapper">
            <h2 className="formTitle">على ماذا تريد أن توفر المال؟</h2>
            <Selector onSelect={onSelect} values={[
                'مطاعم 🍽️', // Restaurants
                'متاجر 🛍️', // Shops
                'علامات تجارية عبر الإنترنت 💻', // Online brands
                'متاجر العافية 🌿', // Wellness shops
                'صحة الرجال 🏋️‍♂️', // Men's health
                'المنزل والديكور 🏠', // Home & decoration
                'أخرى 🔄', // Others
                'أنشطة 🏄‍♂️', // Activities
                'فنادق 🏨', // Hotels
                'حرف ودورات 🎨' // Crafts & Courses
            ]} />

            <div className="button" onClick={nextStep}>التالي</div>
        </div>
    );
}

const CityInput = ({ onChange, submit}) => {
    return (
        <div className="wrapper">
            <h2 className="formTitle">ما هي مدينتك؟</h2>
            <input type="text" className="cityInput" placeholder="الدار البيضاء" onChange={onChange} />
            <div className="button" onClick={submit}>التالي</div>
        </div>
    );
}

export default UserSignUp;