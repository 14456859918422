import React from "react";
import '../style/global.css';
import api from "../services/api";

var baseUrl = "https://bzzaf-a0831bc92be3.herokuapp.com/api";

const PreSignUp = () => {

    const handleGoogleAuth = () => {
        window.location.href = `${baseUrl}/auth/google`;
    }

    return (
        <div className="wrapper">
            <h1 className="title">لكل عملية شراء،<br /><span>توفير مذهل بانتظارك!</span></h1>

            
            <div className="button" onClick={handleGoogleAuth}>انضم إلى Bzzaf.ma</div>
            <p className="subtitle">
            حاليًا، <span>5000+</span> شخصًا في قائمة الانتظار.
            </p>
        </div>
    );
};

export default PreSignUp;