// src/services/api.js

import axios from 'axios';

var API_URL = 'https://bzzaf-a0831bc92be3.herokuapp.com/api';
if (process.env.NODE_ENV === 'development'){
  API_URL = 'http://localhost:3001/api';
}

const api = axios.create({
  baseURL: API_URL
});

export default api;