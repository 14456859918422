import AppController from "./components/AppController";


function App() {
  return (
    <AppController  />
  );
}

export default App;
