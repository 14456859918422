import React from "react";
import '../style/global.css';
import '../style/home.css';

const Home = () => {
    const handleRedirect = () => {
        //window.location.href = "/selection";
        
        window.open("/selection", "_blank");
    };

    return (
        <div className="wrapper home">
            <h1 className="title">لكل عملية شراء،<br /><span>توفير مذهل بانتظارك!</span></h1>


            <p className="subtitle">
                الأسعار تخرج عن السيطرة.<br />
                انضم إلى Bzzaf.ma ووفر<br />
                حتى 50% على الخدمات التي تحبها.
            </p>
            

            <div className="button" onClick={handleRedirect}>انضم الآن! 💥</div>
        </div>

    );
};

export default Home;
