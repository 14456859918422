import React from "react";
import '../style/global.css';


const UserOnboardingWrapper = ({ children }) => {
    return (
     {children}   
    );
};


const UserOnboarding1 = () => {
    return (
        <div className="wrapper onboarding">
            <h1 className="title">لكل عملية شراء،<br /><span>توفير مذهل بانتظارك!</span></h1>


            
            <img className="coupons" src="/coupons.png"></img>

            <p className="subtitle">
            احصل على أكواد القسائم لاستخدامها مباشرةً في المتاجر.
            </p>
            <div className="button" onClick={ () => window.location.href = "/user_onboarding2" }>التالي</div>
        </div>
    );
}

const UserOnboarding2 = () => {
    return (
        <div className="wrapper onboarding onboarding2">
            <h1 className="title">لكل عملية شراء،<br /><span>توفير مذهل بانتظارك!</span></h1>


            
            <img className="coupons" src=""></img>

            
            <p className="subtitle">
            اكتشف عروض مذهلة بالقرب منك لاستكشاف أماكن جديدة وتوفير المال.
            </p>
            <div className="button" onClick={ () => window.location.href = "/presignup" }>التالي</div>
        </div>
    );
}

const UserOnboarding3 = () => {
    return (
        <UserOnboardingWrapper>
            
        </UserOnboardingWrapper>
    );
}

const UserOnboarding4 = () => {
    return (
        <UserOnboardingWrapper>
            
        </UserOnboardingWrapper>
    );
}

const UserOnboarding5 = () => {
    return (
        <UserOnboardingWrapper>
            
        </UserOnboardingWrapper>
    );
}

export { UserOnboarding1, UserOnboarding2, UserOnboarding3, UserOnboarding4, UserOnboarding5 };