import React from "react";
import '../style/global.css';
import Code from "./Code";

const Share = () => {
    const shareUrl = "https://bzzaf.ma"; // URL to share

    const handleShare = async () => {
        if (navigator.share) {
            try {
                await navigator.share({
                    title: 'Check this out!', // Title of the share
                    url: shareUrl, // URL to share
                });
                console.log('Content shared successfully');
            } catch (error) {
                console.error('Error sharing content', error);
            }
        } else {
            // Fallback for browsers that do not support the Web Share API
            console.log('Web Share not supported on this browser');
        }
    };

    return (
        <div className="wrapper">
            <h2 className="subtitle">لقد سئمنا من الأسعار المرتفعة. دعونا نبني معًا اقتصادًا مشتركًا لخفض الأسعار.</h2>
            <div className="selection_box">
                <div className="selection">
                    <p>احصل على العروض المذهلة القريبة منك بالمئات</p>
                    <h4>شارك مع صديقك :</h4>
                    <Code text={shareUrl} />
                </div>
            </div>
            
            <div className="button" onClick={handleShare}>شارك</div>
        </div>
    );
};

export default Share;
