import React from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from "./Home";
import Selection from "./Selection";
import UserSignUp from "./UserSignUp";
import Share from "./Share";
import BusinessSignUp from "./BusinessSignUp";
import { UserOnboarding1, UserOnboarding2 } from "./UserOnboarding";
import PreSignUp from "./PreSignUp";
import WhatsappThanks from "./WhatsappThanks";

const AppController = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/selection" element={<Selection />} />
                <Route path="/newuser" element={<UserSignUp />} />
                <Route path="/business_signup" element={<BusinessSignUp />} />
                <Route path="/user_onboarding1" element={<UserOnboarding1 />} />
                <Route path="/user_onboarding2" element={<UserOnboarding2 />} />
                <Route path="/presignup" element={<PreSignUp />} />
                <Route path="/whatsapp" element={<WhatsappThanks />} />
                <Route path="/share" element={<Share />} />
                {/* No need for a default route like this, "/" handles it */}
            </Routes>
        </Router>
    );
};

export default AppController;
