import React from "react";
import '../style/global.css';
import Selector from "./Selector";
import api from "../services/api";


const BusinessSignUp = ({ setStep }) => {

    const [formStep, setFormStep] = React.useState(1);
    const [interests, setInterests] = React.useState([]);
    const [city, setCity] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [whatsapp, setWhatsapp] = React.useState("");

    const nextStep = () => {
        setFormStep(formStep + 1);
    }

    const submit = () => {
        api.post('/business', { interests, city, email, whatsapp }).then((res) => {
            window.location.href = "/whatsapp";
        }).catch((err) => {
            window.notifyRed("Error while submitting your information.");
        });
    }

    switch (formStep) {
        case 1:
            return <InterestSelect nextStep={nextStep} onSelect={setInterests} />;
        case 2:
            return <CityInput submit={nextStep} onChange={(e) => setCity(e.target.value)} />;
        case 3:
            return <EmailInput submit={nextStep} onChange={(e) => setEmail(e.target.value)} />;
        case 4:
            return <WhatsappInput submit={submit} onChange={(e) => setWhatsapp(e.target.value)} />;
        default:
            return "";
    }

};

const InterestSelect = ({ onSelect, nextStep }) => {
    return (
        <div className="wrapper">
            <h2 className="formTitle">على ماذا تريد أن توفر المال؟</h2>
            <Selector onSelect={onSelect} values={[
                'مطاعم 🍽️', // Restaurants
                'متاجر 🛍️', // Shops
                'علامات تجارية عبر الإنترنت 💻', // Online brands
                'متاجر العافية 🌿', // Wellness shops
                'صحة الرجال 🏋️‍♂️', // Men's health
                'المنزل والديكور 🏠', // Home & decoration
                'أخرى 🔄', // Others
                'أنشطة 🏄‍♂️', // Activities
                'فنادق 🏨', // Hotels
                'حرف ودورات 🎨' // Crafts & Courses
            ]} />

            <div className="button" onClick={nextStep}>التالي</div>
        </div>
    );
}

const CityInput = ({ onChange, submit }) => {
    return (
        <div className="wrapper">
            <h2 className="formTitle">ما هي مدينتك؟</h2>
            <input type="text" className="cityInput" placeholder="الدار البيضاء" onChange={onChange} />
            <div className="button" onClick={submit}>التالي</div>
        </div>
    );
}

const EmailInput = ({ onChange, submit }) => {
    return (
        <div className="wrapper">
            <h2 className="formTitle">ما هو بريدك الإلكتروني؟</h2>
            <input type="email" className="emailInput" placeholder="example@example.com" onChange={onChange} />
            <div className="button" onClick={submit}>التالي</div>
        </div>
    );
}

const WhatsappInput = ({ onChange, submit }) => {
    return (
        <div className="wrapper">
            <h2 className="formTitle">ما هو رقم هاتفك على الواتساب؟</h2>
            <input type="text" className="whatsappInput" placeholder="رقم الهاتف" onChange={onChange} />
            <div className="button" onClick={submit}>إنهاء</div>
        </div>
    );
}

export default BusinessSignUp;