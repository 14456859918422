import React, { useState, useEffect } from 'react';

const Selector = ({ values, onSelect }) => {
    const [selected, setSelected] = useState([]);

    const handleSelect = (value) => {
        if (selected.includes(value)) {
            setSelected(selected.filter(item => item !== value));
        } else {
            setSelected([...selected, value]);
        }
    };

    useEffect(() => {
        onSelect(selected);
    }, [selected]);

    return (
        <div className='selector'>
            <div className='selector-options'>
                {values.map((value, index) => (
                    <div
                        key={index}
                        className={`selector-option ${selected.includes(value) ? 'selected' : ''}`}
                        onClick={() => handleSelect(value)}
                    >
                        {value}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Selector;